/**
 * Scripts
 */

require('./components/banner-slider.js')();
require('./components/hero-slider.js')();
require('./components/lazy-background.js')();
require('./components/menu-masonry.js')();
require('./components/mobile-menu.js')();
require('./components/responsive-table.js')();
require('./components/smooth-scroll.js')();



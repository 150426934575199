module.exports = function(){

    var hashTagActive = '';

    $('.js-smooth-scroll').click(function(event) {
        event.preventDefault();

        // Calculate destination

        var dest = 0,
            currentHash = $(this.hash);

        if (currentHash.offset().top > $(document).height() - $(window).height()) {
            dest = $(document).height() - $(window).height();
        } else {
            dest = currentHash.offset().top;
        }

        // Go to destination

        $('html, body').animate({
            scrollTop: dest - 50
        }, 1000, 'swing', function() {

            // Add a class to the div

            currentHash.addClass('is-scrolled-to');

            // If there's an input in there, focus on it

            if(currentHash.find('input').length) {
                var activeInput = currentHash.find('input[type=text],textarea,select').filter(':visible:first');
                $(activeInput).focus();
            }
        });
        hashTagActive = this.hash;
    });

};
module.exports = function(){

	window.responsiveTable = {
		tableElement: 'table',
		init() {
			var tables = $(document).find(this.tableElement);
			if(tables.length) {
				tables.each(function(index, table) {
					var tableHTML = $(table)[0].outerHTML;
					$(table).replaceWith('<div class="responsive-table">' + tableHTML + '</div>');
				});
			};
		}
	};

	responsiveTable.init();
};

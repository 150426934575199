module.exports = function(){
    var heroSlider = $('.js-hero-slider');
    heroSlider.slick({
    	adaptiveHeight: false,
    	arrows: true,
    	autoplay: true,
    	autoplaySpeed: 5000,
    	dots: false,
    	fade: true,
    	cssEase: 'ease',
    	speed: 600,
    });
};
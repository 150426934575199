module.exports = function(){
    var bannerSlider = $('.js-banner-slider');
    bannerSlider.slick({
    	adaptiveHeight: false,
    	arrows: false,
    	autoplay: true,
    	autoplaySpeed: 4000,
    	dots: true,
    	fade: true,
    	cssEase: 'ease',
    	speed: 600,
    });
};
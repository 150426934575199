module.exports = function(){
	
	var menuMasonry = {
		containerClass: '.main-menu__item.main-menu__item--parent > .main-menu__sub-list',
		itemClass: '.main-menu__sub-item',
		setMenuHeight() {
			var self = this;
			var containers = $(this.containerClass);
			containers.each(function(i, container) {
				// Need to add class first to get correct height
				$(container).addClass('is-mega');
				var heightArray = [];
				var childLists = $(container).find('> ' + self.itemClass);
				childLists.each(function(j, list) {
					if($(list).height() > 400) {
						$(list).css('columns', '2');
						$(list).css('max-width', '50%');
					}
					heightArray.push($(list).height());
				});
				var largestList = Math.max.apply(Math, heightArray) + 60;
				// Remove class if it wasn't needed
				if(largestList < 200) {
					$(container).removeClass('is-mega');
				}
				if(largestList > 200) {
					$(container).css('max-height', largestList);
					$(container).parent().addClass('has-mega');
				}
			});
		},
		init() {
			var containers = $(this.containerClass);
			if(containers.length && $(window).width() > 800) {
				this.setMenuHeight();
			}
		}
	}
	menuMasonry.init();
}